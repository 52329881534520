import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import HomePage from "./pages/Home";
import Terms from "./pages/Terms";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import HelpPage from "./pages/Help";
import { Helmet } from "react-helmet";
import Dashboard from "./pages/Dashboard";
import { useEffect } from "react";
import { AUTH_TOKEN } from "./constants";
import { useAuthContext } from "./context/authContext";

function App() {
  const { setToken } = useAuthContext();

  useEffect(() => {
    const token = localStorage.getItem(AUTH_TOKEN);
    if (token && typeof token === "string") setToken(token);
  }, []);

  return (
    <div className="App">
      <Helmet>
        <title>{window?.location?.hostname}</title>
      </Helmet>
      <Router>
        <Routes>
          <Route
            path="/AIzaSyCvhg2ukGcat6zD8WwnAJCVrYjtc85P_J0"
            element={<Dashboard />}
          />
          <Route path="/my-dashboard" element={<Dashboard />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/help" element={<HelpPage />} />
          <Route path="*" element={<HomePage />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
