import React, { useState } from "react";
import { useAuthContext } from "../context/authContext";
import axios from "axios";
import { AUTH_TOKEN, BASE_URL } from "../constants";

function LoginPage() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const { token, setToken } = useAuthContext();
  const [loading, setLoading] = useState(false);

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);

    await axios
      .post(
        `${BASE_URL}/api/auth/login`,
        { username, password },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        setToken(res?.data?.token);
        localStorage.setItem(AUTH_TOKEN, res?.data?.token);
      })
      .catch((err) => {
        alert(err?.response?.data?.message);
      });

    setLoading(false);
  };

  return (
    <div class="bg-gray-100 flex justify-center items-center h-screen">
      <div class="bg-white p-8 rounded-lg shadow-md w-96">
        <h1 class="text-2xl font-semibold mb-6">Login</h1>
        <form onSubmit={handleLogin}>
          <div class="mb-4">
            <label
              for="username"
              class="block text-gray-700 text-sm font-bold mb-2"
            >
              Username
            </label>
            <input
              type="text"
              id="username"
              value={username}
              onChange={(e) => setUsername(e?.target.value)}
              maxLength={30}
              class="bg-gray-200 appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
              placeholder="Enter your username"
              required
            />
          </div>
          <div class="mb-6">
            <label
              for="password"
              class="block text-gray-700 text-sm font-bold mb-2"
            >
              Password
            </label>
            <input
              type="text"
              id="password"
              value={password}
              onChange={(e) => setPassword(e?.target.value)}
              maxLength={30}
              class="bg-gray-200 appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
              placeholder="Enter your password"
              required
            />
          </div>
          <button
            disabled={loading}
            type="submit"
            class="disabled:bg-gray-400 w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline-blue active:bg-blue-800"
          >
            Login
          </button>
        </form>
        {/* <p class="text-gray-600 text-xs mt-4">
          Don't have an account?{" "}
          <a href="#" class="text-blue-500 hover:underline">
            Sign up
          </a>
        </p> */}
      </div>
    </div>
  );
}

export default LoginPage;
