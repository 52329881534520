import React from "react";

const Terms = () => {
  return (
    <div className="max-w-3xl mx-auto p-4 sm:p-8">
      <h1 className="font-bold text-[2rem] mb-3">Terms of Use</h1>

      <p className="mb-3">
        <time className="font-semibold">Last updated : {new Date().toString().slice(0, 21)}</time>
      </p>
      <div id="rampjs_ip1"></div>
      <div className="terms__container">
        <div className="mb-4">
          <p>
            Before using this Web site, please read the Terms of Use set forth
            below. By using this Web site you agree to be bound by the Terms of
            Use. System1 LLC and its affiliates and subsidiaries (including
            Infospace Holdings LLC, Qool Media Holdings LLC, Dotzup Holdings
            LLC, Concourse Media Holdings LLC, MapQuest Holdings LLC, Privacy
            One Group Limited and System1 Waterfox Holdings LLC) reserve the
            right to review and revise the Terms of Use from time to time
            without prior notice and, by using this site subsequent to any
            revision of the Terms of Use, you agree to be bound by such changes.
            If you find the Terms of Use to be unacceptable, you must
            immediately terminate your use of this Web site. This Web site is
            made available for your personal, non-commercial use only. You may
            not use this Web site to sell a product or service, or to increase
            traffic to your Web site for commercial reasons, such as advertising
            sales. You may not take the results from any type of Web search and
            reformat and display them, or mirror the Web site home page or
            results pages on your Web site. If you want to make commercial use
            of the Web site, you must enter into an agreement with the System1
            Site to do so in advance. Please contact us for more information.
          </p>
        </div>
        <div className="mb-4">
          <h3 className="font-bold text-2xl mb-3">1. No Representations Or Warranties</h3>
          <p>
            The services provided on this Web site and the content, information,
            documents, graphics and images published at this Web site could
            include inaccuracies, typographical errors or other errors. We make
            no commitment, however, to update what is contained in this Web
            site. Furthermore, we reserve the right to temporarily, or
            permanently, modify, alter, discontinue or delete the same without
            prior notice. Consequently,
          </p>
          <p>
            TO THE EXTENT PERMITTED BY LAW, THE SERVICES AND INFORMATION ON THIS
            WEB SITE ARE PROVIDED “AS IS,” AND WITHOUT WARRANTY, AND ALL
            WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING, BUT
            NOT LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS
            FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT, ARE HEREBY
            DISCLAIMED, AND YOU HEREBY WAIVE ALL SUCH WARRANTIES.
          </p>
          <p>
            Users of this Web site should not rely upon opinions expressed at
            this Web site when making business, financial, personal or other
            decisions. Furthermore, we do not endorse the opinions of third
            parties expressed on this Web site or on linked Web sites.
          </p>
        </div>
        <div className="mb-4">
          <h3 className="font-bold text-2xl mb-3">2. Privacy Policy</h3>
          <p>
            The information that System1 obtains through your use of our sites,
            is subject to our
            <a href="https://system1.com/terms/privacy-policy">
              Privacy Policy{" "}
            </a>
            . That
            <a href="https://system1.com/terms/privacy-policy">
              privacy policy
            </a>
            contains terms and conditions that govern our collection and use of
            the information you provide use and our respective rights relative
            to that information. Please review our
            <a href="https://system1.com/terms/privacy-policy">
              Privacy Policy
            </a>
            before you use our sites. Your use of our sites indicates an
            agreement to our
            <a href="https://system1.com/terms/privacy-policy">
              Privacy Policy
            </a>
            .
          </p>
        </div>
        <div className="mb-4">
          <h3 className="font-bold text-2xl mb-3">3. User Warnings/User Conduct</h3>
          <p>
            We make certain services available, including without limitation,
            e-mail, chat rooms, message boards and other community services;
            provided that you agree to abide by the terms and conditions
            contained in these Terms of Use. Without limiting the generality of
            the foregoing, in consideration for our providing such services, you
            also agree not to:
          </p>
          <ul className="mb-4List">
            <li>Violate any applicable law, regulation or rule;</li>
            <li>
              Harass, offend, threaten, embarrass, distress or invade the
              privacy of any individual or entity;
            </li>
            <li>
              Post or transmit any commercial, advertising or promotional
              materials, including without limitation, “spam” or mass
              distributions;
            </li>
            <li>
              Provide false information on your registration form or impersonate
              another person at any point;
            </li>
            <li>
              Post or transmit false, inappropriate, improper, disorderly or
              excessive messages or information.
            </li>
            <li>
              Post any material that infringes upon any third party’s copyright,
              trademark, patent or other intellectual property right.
            </li>
          </ul>
          <p>
            If you violate such terms, we may, without prior notice and without
            liability to you, ban you from any and all services, terminate your
            registration, delete your messages or postings, and take any other
            action we deem appropriate in our sole discretion. With reference to
            any services we provide, you acknowledge that we may, from time to
            time, without notice to you and at our sole discretion, establish
            various practices, limitations and restrictions for administering
            such services, including without limitation: how often a service or
            account may be accessed; the maximum number of days that messages or
            postings will be retained; the maximum number of messages or
            postings allowed; the size and nature of messages and postings; the
            maximum storage space available for an account; and all other
            matters related to administration of services, and you agree to be
            bound by such practices. You agree that we are not responsible or
            liable for deactivation or deletion of accounts or for loss of
            e-mails, communications, postings, data or information as a result
            of, or arising out of, our administration of such services.
          </p>
          <p>
            We assume no responsibility for the accuracy, integrity, quality
            completeness, usefulness or value of any content, data, documents,
            graphics, images, information, advice, or opinion contained in any
            e-mails, message boards, chat rooms or community services, or in any
            other public services, and do not endorse any advice or opinion
            contained therein. We do not monitor or control such services,
            although we reserve the right to do so. We may take any action we
            deem appropriate, in our sole discretion, to maintain the high
            quality of our service and to protect ourselves and others.
          </p>
        </div>
        <div className="mb-4">
          <h3 className="font-bold text-2xl mb-3">4. Affiliate Links</h3>
          <p>
            If a search result includes “Aff” or “Affiliate”, an affiliate code
            has been added to the link and System1 may receive financial
            compensation related to that search result.
          </p>
        </div>
        <div className="mb-4">
          <h3 className="font-bold text-2xl mb-3">5. Web Site Links And Third-Party Sites</h3>
          <p>
            This Web site may contain links to other Web sites which are
            independent of this Web site. We make no representation or warranty
            as to the accuracy, value, integrity, completeness or authenticity
            of the information or opinions contained in any such linked Web
            site, and any link to another Web site shall not in any manner be
            construed as an endorsement by us of that Web site, or of the
            products or services described therein.
          </p>
          <p>
            Furthermore, these links may lead to sites or links that contain
            offensive and objectionable content or which may contain dangerous
            computer viruses. You assume, and we hereby disclaim, all
            responsibility for any of the content on these sites or for any
            damage sustained by users of these sites.
          </p>
        </div>
        <div className="mb-4">
          <h3 className="font-bold text-2xl mb-3">6. Confidentiality</h3>
          <p>
            Your confidential use of this site cannot be guaranteed by us. We
            shall not be responsible for any harm that you or any person may
            suffer as a result of a breach of confidentiality in respect to your
            use of this site.
          </p>
        </div>
        <div className="mb-4">
          <h3 className="font-bold text-2xl mb-3">7. Security</h3>
          <p>
            You may have an account and password. You are responsible for
            maintaining the confidentiality of your password and account
            information and are fully responsible for all conduct carried out
            under this password and account. We are not liable for any loss of
            confidentiality or for any damages arising from your failure to
            comply with these terms. You will promptly report any unauthorized
            use of your password to us.
          </p>
        </div>
        <div className="mb-4">
          <h3 className="font-bold text-2xl mb-3">8. Limitation of Damages</h3>
          <p>
            IN NO EVENT WILL WE, OUR OFFICERS, EMPLOYEES, CONTRACTORS,
            SUBCONTRACTORS, SUPPLIERS, AGENTS, AFFILIATES, SUBSIDIARIES,
            SUCCESSORS OR ASSIGNS BE LIABLE TO ANY PARTY FOR ANY DIRECT,
            INDIRECT, CONSEQUENTIAL, INCIDENTAL, SPECIAL OR OTHER INDIRECT
            (INCLUDING WITHOUT LIMITATION, COST OF COVER) ARISING OUT OF OR IN
            CONNECTION WITH THIS AGREEMENT OR ANY SERVICES, CONTENT OR OTHER
            MATERIALS PROVIDED OR AVAILABLE HEREUNDER, OR USE OF ANY OTHER LINKS
            OR LINKED WEB SITE, EVEN IF WE ARE EXPRESSLY ADVISED OF THE
            POSSIBILITY OF SUCH DAMAGES, AND REGARDLESS OF WHETHER SUCH DAMAGES
            AROSE IN CONTRACT, TORT (INCLUDING NEGLIGENCE), STRICT LIABILITY OR
            OTHER LEGAL BASIS. THE TERM “DAMAGES” INCLUDES, WITHOUT LIMITATION,
            ATTORNEY FEES, ANY LOST PROFITS, BUSINESS INTERRUPTION AND LOSS OF
            PROGRAMS OR OTHER DATA ON YOUR INFORMATION HANDLING SYSTEM. YOU
            ACKNOWLEDGE THAT THE ECONOMIC TERMS OF OUR AGREEMENT REFLECT THE
            FOREGOING ALLOCATION OF RISK AND SUCH ALLOCATION OF RISK IS A
            SIGNIFICANT INDUCEMENT FOR US TO PROVIDE THE SERVICES, WEB SITE AND
            OTHER CONTENT AND MATERIALS.
          </p>
        </div>
        <div className="mb-4">
          <h3 className="font-bold text-2xl mb-3">9. Ownership</h3>
          <p>
            We own all right, title and interest in and to the websites, the
            products and services offered on the websites as well as our logos,
            tradenames, and trademarks. All other rights, title and interest
            (including all other copyrights, trademarks and other intellectual
            property rights) in this Web site and all names, terms, logos,
            slogans, images and other indicia identifying System1’s products or
            services are proprietary marks belonging to System1. The names of
            companies and products not owned by System1 and mentioned herein may
            be the trademarks of their respective owners. Any use of the
            copyrighted contents or the trademarks belonging to System1 without
            the express written permission of System1 is strictly prohibited.
          </p>
        </div>
        <div className="mb-4">
          <h3 className="font-bold text-2xl mb-3">10. License</h3>
          <p>
            Nothing contained in this Web site shall be construed as conferring
            any license or right, expressly, by implication, by estoppel or
            otherwise, under any of our intellectual property rights, or under
            any third party’s intellectual property rights, and no part of this
            Web site may be reproduced, republished, copied, transmitted, or
            distributed in any form or by any means.
          </p>
        </div>
        <div className="mb-4">
          <h3 className="font-bold text-2xl mb-3">11. Claims of Copyright Infringement</h3>
          <p>
            System1 respects the intellectual property of others and may, in
            appropriate circumstances and at its discretion, terminate the
            accounts of users who infringe the intellectual property rights of
            others. If you believe that any material on this Web site infringes
            on any copyright which you own or control, or that any link on this
            Web site directs you to another Web site that contains material that
            infringes on any copyright which you own or control, you may file a
            notification of such infringement with our designated agent to have
            the material removed or otherwise blocked from access. Please see
            the
            <a
              href="https://system1.com/terms/copyright"
              title="NOTICE AND PROCEDURE FOR MAKING CLAIMS OF COPYRIGHT INFRINGEMENT"
            >
              NOTICE AND PROCEDURE FOR MAKING CLAIMS OF COPYRIGHT INFRINGEMENT{" "}
            </a>
            .
          </p>
        </div>
        <div className="mb-4">
          <h3 className="font-bold text-2xl mb-3">12. Indemnification</h3>
          <p>
            YOU AGREE TO HOLD HARMLESS, DEFEND AND INDEMNIFY US, OUR OFFICERS,
            DIRECTORS, EMPLOYEES, SUBSIDIARIES, CONTRACTORS, SUBCONTRACTORS,
            SUPPLIERS, AGENTS, PARTNERS AND AFFILIATES, SUCCESSORS AND ASSIGNS
            FROM ALL LIABILITIES, CLAIMS, DEMANDS AND EXPENSES, INCLUDING
            REASONABLE ATTORNEYS’ FEES, THAT ARE DUE TO, OR THAT ARISE FROM YOUR
            USE OR MISUSE OF THIS SITE, ANY SERVICES THEREIN, OR FOR
            INFRINGEMENT BY YOU OR OTHERS OF INTELLECTUAL PROPERTY RIGHTS OR
            OTHER RIGHT OF ANY THIRD PARTY. WE MAY ASSUME EXCLUSIVE CONTROL OF
            ANY DEFENSE OR ANY MATTER SUBJECT TO INDEMNIFICATION BY YOU, AND YOU
            AGREE TO COOPERATE WITH US IN SUCH EVENT.
          </p>
        </div>
        <div className="mb-4">
          <h3 className="font-bold text-2xl mb-3">13. Governing Laws in Case of Dispute</h3>
          <p>
            THESE TERMS OF USE SHALL BE GOVERNED BY AND CONSTRUED IN ACCORDANCE
            WITH THE LAWS OF THE STATE OF WASHINGTON, USA, AS THEY APPLY TO
            AGREEMENTS MADE AND SOLELY PERFORMED THEREIN. YOU IRREVOCABLY
            CONSENT AND WAIVE ALL OBJECTION TO PERSONAL JURISDICTION AND VENUE
            IN THE STATE AND FEDERAL COURTS LOCATED IN KING COUNTY, WASHINGTON,
            USA, AND YOU SHALL NOT COMMENCE OR PROSECUTE ANY SUIT OR ACTION
            EXCEPT IN THE FOREGOING COURTS.
          </p>
        </div>
        <div className="mb-4">
          <h3 className="font-bold text-2xl mb-3">14. International Use</h3>
          <p>
            We do not represent that all content, materials and services on our
            Web site are appropriate or available for use in all geographic
            locations, especially some locations outside the United States, and
            accessing such from certain locations may be illegal and prohibited.
            Those who do access content, materials and services from such
            locations act on their own initiative and we are not responsible for
            their compliance with local laws or other applicable laws. You will
            not access the foregoing where prohibited by law.
          </p>
        </div>
        <div className="mb-4">
          <h3 className="font-bold text-2xl mb-3">15. Integration; Severability; General</h3>
          <p>
            These Terms of Use incorporate by reference any notices contained on
            this Web site and constitute the entire agreement with respect to
            your access to and use of this Web site. We may modify these Terms
            of Use at any time by posting revised Terms of Use on our Web site
            and your continuing use of such Web site and the services
            constitutes your agreement to be bound by such modified Terms of
            Use. Any provision of these Terms of Use which is determined by a
            court of competent jurisdiction to be unenforceable in any
            jurisdiction shall be severable from these Terms of Use in that
            jurisdiction without in any way invalidating the remaining
            provisions of these Terms of Use. The unenforceability of any
            provision in a given jurisdiction shall not make that provision
            unenforceable in any other jurisdiction.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Terms;
