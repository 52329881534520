import React from "react";

const PrivacyPolicy = () => {
  return (
    <div className="w-full max-w-5xl mx-auto py-8 px-4 privacyPolicyshointesd">
      <article className="post">
        <div className="post__container">
          <section>
            <div className="post__container">
              <h1 className="text-2xl font-bold mb-4">Privacy Policy</h1>
            </div>
          </section>
          <section>
            <p>
              <time className="mb-3 font-semibold">
                Last updated: July 5, 2023
              </time>
            </p>
            <p>
              System1 takes your privacy very seriously. In connection with the
              websites, applications and services that we operate, and with
              respect to our communications and relationship with you, we are
              committed to data protection, including through compliance with
              this Privacy Policy. Please read this Privacy Policy carefully, as
              it contains important information about how and why we collect,
              store, use, share and otherwise “process” your “Personal
              Information” (as defined below). It also explains your rights in
              relation to your Personal Information and information on how to
              contact us in the event you have questions, or concerns.
            </p>
            <p>
              For information about the terms upon which we do business, you
              should also read our
              <em>
                <a href="https://system1.com/terms/terms-of-use">
                  Terms of Use.
                </a>
              </em>
            </p>
            <h2 className="text-2xl font-bold my-4">
              Table of contents and summary
            </h2>
            <p>
              <a href="https://system1.com/terms/privacy-policy#key-terms">
                <strong>
                  <em>Key Terms </em>
                </strong>
              </a>
            </p>
            <div id="rampjs_ip4"></div>
            <ul>
              <li>
                Relevant definitions used throughout this Privacy Policy can be
                found
                <a href="https://system1.com/terms/privacy-policy#key-terms">
                  here
                </a>
                .
              </li>
            </ul>
            <div id="rampjs_ip5"></div>
            <p>
              <a href="https://system1.com/terms/privacy-policy#personal-information">
                <strong>
                  <em>Personal Information we collect about you </em>
                </strong>
              </a>
            </p>
            <ul>
              <li>
                The Personal Information we process about you depends on how you
                interact with our Services (as defined below) but can include
                (1) information you provide to us directly, and (2) information
                we collect through automated data collection or tracking
                technologies.
              </li>
              <li>
                Additionally, third parties may use automated data collection or
                tracking technologies within our Services to collect information
                about you.
              </li>
              <li>
                <a href="https://system1.com/terms/privacy-policy#personal-information">
                  More information can be found here
                </a>
                .
              </li>
            </ul>
            <p>
              <a href="https://system1.com/terms/privacy-policy#personal-information-how-why">
                <strong>
                  <em>How and why we use your Personal Information</em>
                </strong>
              </a>
            </p>
            <ul>
              <li>
                We use your Personal Information to understand how you use our
                Services, to provide our Services to you, maintain records of
                our interactions with you, to respond to your enquiries, to
                notify you of changes to our Services, to serve advertisements
                to you on our Services or for marketing purposes, and to allow
                you to participate in any interactive features of our Services.
                We may also use your Personal Information to enforce our legal
                rights and inform our business strategy.
                <a href="https://system1.com/terms/privacy-policy#personal-information-how-why">
                  More detailed information can be found here
                </a>
                .
              </li>
            </ul>
            <p>
              <a href="https://system1.com/terms/privacy-policy#dnt">
                <strong>
                  <em>Do Not Track</em>
                </strong>
              </a>
            </p>

            <ul>
              <li>
                Your browser settings may allow you to automatically transmit a
                “Do Not Track” signal to online services you visit, including
                our Services. Note, however, there is no industry consensus as
                to what site and app operators should do with regard to these
                signals. Accordingly, unless and until the law is interpreted to
                require us to do so, we do not monitor or take action with
                respect to “Do Not Track” signals. For more information on “Do
                Not Track,” visit
                <a href="http://www.allaboutdnt.com/">
                  http://www.allaboutdnt.com
                </a>
                .
              </li>
            </ul>
            <div id="rampjs_ip11"></div>
            <p>
              <a href="https://system1.com/terms/privacy-policy#personal-information-who">
                <strong>
                  <em>Who we share your Personal Information with</em>
                </strong>
              </a>
            </p>
            <div id="rampjs_ip12"></div>
            <ul>
              <li>
                We may share your Personal Information within the System1 Group
                (as defined below), with our third-party service providers and
                professional partners, with parties with whom we enter into a
                corporate transaction, law enforcement agencies, courts or
                regulatory bodies (to comply with legal obligations) or
                otherwise with your consent.
                <a href="https://system1.com/terms/privacy-policy#personal-information-who">
                  More information can be found here
                </a>
                .
              </li>
            </ul>
            <div id="rampjs_ip13"></div>
            <p>
              <a href="https://system1.com/terms/privacy-policy#personal-information-duration">
                <strong>
                  <em>How long your Personal Information will be kept</em>
                </strong>
              </a>
            </p>
            <div id="rampjs_ip14"></div>
            <ul>
              <li>
                We retain your Personal Information for as long as is necessary
                to achieve the purposes for which it was collected.
                <a href="https://system1.com/terms/privacy-policy#personal-information-duration">
                  More information can be found here
                </a>
                .
              </li>
            </ul>
            <div id="rampjs_ip15"></div>
            <p>
              <a href="https://system1.com/terms/privacy-policy#international-transfers">
                <strong>
                  <em>International transfers</em>
                </strong>
              </a>
            </p>
            <div id="rampjs_ip16"></div>
            <ul>
              <li>
                In the event that we transfer your Personal Information
                internationally, we take steps to ensure that such Personal
                Information is subject to safeguards in compliance with
                applicable data protection laws.
                <a href="https://system1.com/terms/privacy-policy#international-transfers">
                  More information can be found here
                </a>
                .
              </li>
            </ul>
            <p>
              <a href="https://system1.com/terms/privacy-policy#third-party-links">
                <strong>
                  <em>Links to third party sites and content</em>
                </strong>
              </a>
            </p>
            <ul>
              <li>
                Where you access third party sites or content via our Services,
                it is important that you check the applicable third party’s
                privacy policy to understand how such third parties will handle
                your information.
                <a href="https://system1.com/terms/privacy-policy#third-party-links">
                  More information can be found here
                </a>
                .
              </li>
            </ul>
            <p>
              <a href="https://system1.com/terms/privacy-policy#supplemental-rights">
                <strong>
                  <em>Supplemental U.S. privacy rights and disclosures</em>
                </strong>
              </a>
            </p>
            <ul>
              <li>
                If you are a consumer located in the United States, you may be
                entitled to additional information about our use of your
                Personal Information and you may have certain rights in
                connection with your Personal Information.
                <a href="https://system1.com/terms/privacy-policy#supplemental-rights">
                  More information can be found here
                </a>
                .
              </li>
            </ul>
            <p>
              <a href="https://system1.com/terms/privacy-policy#supplemental-info">
                <strong>
                  <em>
                    Supplemental Information for persons in the EEA and UK
                  </em>
                </strong>
              </a>
            </p>
            <ul>
              <li>
                If you are a consumer located in the European Economic Area
                (EEA) or the United Kingdom (UK), you may be entitled to
                additional information about our use of your Personal
                Information and you may have certain rights in connection with
                our processing of your Personal Information. Details of these
                rights and how to exercise them are
                <a href="https://system1.com/terms/privacy-policy#supplemental-info">
                  set out here
                </a>
                .
              </li>
            </ul>
            <p>
              <a href="https://system1.com/terms/privacy-policy#personal-info-secure">
                <strong>
                  <em>Keeping your Personal Information secure</em>
                </strong>
              </a>
            </p>
            <ul>
              <li>
                We have put in place appropriate security measures to keep your
                Personal Information safe.
                <a href="https://system1.com/terms/privacy-policy#personal-info-secure">
                  More information can be found here
                </a>
                .
              </li>
            </ul>
            <p>
              <a href="https://system1.com/terms/privacy-policy#children-under-16">
                <strong>
                  <em>Children Under the Age of 16</em>
                </strong>
              </a>
            </p>
            <ul>
              <li>
                Use of our Services is not intended for and not directed at
                children under 16 years of age.
                <a href="https://system1.com/terms/privacy-policy#children-under-16">
                  More information can be found here
                </a>
                .
              </li>
            </ul>
            <p>
              <a href="https://system1.com/terms/privacy-policy#policy-changes">
                <strong>
                  <em>Changes to this Privacy Policy</em>
                </strong>
              </a>
            </p>
            <ul>
              <li>
                This Privacy Policy is updated from time to time.
                <a href="https://system1.com/terms/privacy-policy#policy-changes">
                  More information can be found here
                </a>
                .
              </li>
            </ul>
            <p>
              <a href="https://system1.com/terms/privacy-policy#contact-us">
                <strong>
                  <em>How to contact us</em>
                </strong>
              </a>
            </p>
            <ul>
              <li>
                You can contact us online, by post, or email. More information,
                including our contact details,
                <a href="https://system1.com/terms/privacy-policy#contact-us">
                  can be found here
                </a>
                .
              </li>
            </ul>
            <h2 className="text-2xl font-bold my-4">1. Key terms</h2>
            <p>
              Throughout this Privacy Policy, we use the following key terms
              (defined below):
            </p>
            <table className="terms-table">
              <tbody>
                <tr>
                  <td>
                    <p>
                      <strong>System1, we, us, our</strong>
                    </p>
                  </td>
                  <td>
                    <p>
                      We are System1 OpCo, LLC, herein referred to as “System1”,
                      “we”, “us” or “our”.
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <strong>System1 Group</strong>
                    </p>
                  </td>
                  <td>
                    <p>
                      System1 OpCo, LLC, together with any subsidiary companies
                      owned and controlled by System1 OpCo, LLC.
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <strong>Personal Information</strong>
                    </p>
                  </td>
                  <td>
                    <p>
                      Any information relating to an individual or household (as
                      applicable), from which that individual can be identified
                      (whether directly or indirectly).
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <strong>You or Your</strong>
                    </p>
                  </td>
                  <td>
                    <p>
                      The individual to whom the Personal Information relates.
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <strong>Services</strong>
                    </p>
                  </td>
                  <td>
                    <p>
                      This Privacy Policy applies to the System1 sites which
                      directly link to this Privacy Policy (including all
                      associated sub-domains) and related services offered
                      therein. This Privacy Policy does not apply to those sites
                      and services that do not display or link to this Privacy
                      Policy, or that have their own privacy policies.
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
            <h2 className="text-2xl font-semibold my-4">
              2. Personal Information we collect about you
            </h2>
            <p>
              The Personal Information we process about you depends on how you
              interact with our Services. For example:
            </p>
            <ul>
              <li>
                <strong>
                  <em>Information you provide to us directly</em>
                </strong>
                :
                <ul>
                  <li>
                    Any information submitted to us when you fill in a form or
                    query field on any of our Services, register to use any of
                    our Services, apply for a job, or subscribe to any of our
                    Services (including your name and email address, or certain
                    demographic information (e.g., your age) as applicable);
                  </li>
                  <li>
                    Information contained in any messages, emails or other
                    correspondence you have with us in the event that you
                    contact us (including your contact details and the content
                    of such communications); and
                  </li>
                  <li>
                    Information contained in and associated with anything you
                    post to our Services.
                  </li>
                </ul>
              </li>
              <li>
                <strong>
                  <em>
                    Information we collect through automated data collection or
                    tracking technologies
                  </em>
                </strong>
              </li>
            </ul>
            <p>
              As you use our Services, we may use automated data collection or
              tracking technologies to collect certain information about your
              equipment, browsing actions, and usage patterns, including:
            </p>
            <ul>
              <li>
                <ul>
                  <li>
                    Details of your use of our Services, including traffic data,
                    referral data, location data, logs, and other communication
                    data and the resources that you use (or information that you
                    retrieve) on our websites and applications and any
                    advertisement offerings or links to third party websites
                    accessed, acquired or considered and related metrics;
                  </li>
                  <li>
                    Information about your computer or other device and internet
                    connection through which you access or interact with our
                    Services, including your IP address, operating system,
                    device type, device ID, and browser type; and
                  </li>
                  <li>
                    Other electronic network activity information — such as
                    browsing history, viewing history, search history and
                    information regarding interactions with our websites or
                    Services (as applicable).
                  </li>
                </ul>
              </li>
            </ul>
            <p>
              If applicable, we also may use these technologies to collect
              information about your online activities over time and across
              third-party websites or other online services which you may use (
              <em>e.g.</em>, behavioral tracking).
            </p>
            <p>
              The information we collect automatically is generally statistical
              data, but we may maintain it or associate it with Personal
              Information that we (i) collect in other ways or (ii) receive from
              third parties that we work with. We use this information to
              improve our products and services (including our Services), and to
              deliver better, more efficient and more personalized services to
              our users over time.
            </p>
            <p>
              The tracking technologies we use for this automated data
              collection may include:
            </p>
            <ul>
              <li>
                <ul>
                  <li>
                    Cookies (or browser cookies). A cookie is a small file
                    placed on the hard drive of your computer.
                  </li>
                  <li>
                    Flash Cookies. Certain features of our Services may use
                    local stored objects (or “flash cookies”) to collect and
                    store information about your preferences and navigation to,
                    from, and on our Services.
                  </li>
                  <li>
                    Web Beacons. Portions of our Services may contain small
                    electronic files known as web beacons (sometimes also
                    referred to as clear gifs, pixel tags, and single-pixel
                    gifs) that allow us, for example, to count users who have
                    visited pages, track where they have been referred from,
                    track and report conversions, and to generate other related
                    website statistics (for example, recording the popularity of
                    certain website content and verifying system and server
                    integrity).
                  </li>
                </ul>
              </li>
            </ul>
            <p>
              Depending on the location from which you access our Services, you
              may refuse to accept the use of these technologies by activating
              the appropriate settings on your browser, or (where applicable) by
              refusing to provide your consent to the use of these technologies
              when you access our Services. Where you do not consent to the use
              of such technologies, please note that certain parts of our
              Services may no longer be available or may not function as
              intended.
            </p>
            <ul>
              <li>
                <strong>
                  <em>
                    Third party use of cookies and other tracking technologies
                  </em>
                </strong>
                <ul>
                  <li>
                    Some content or applications, including advertisements,
                    available through our Services are served or provided by
                    third parties, including advertisers, ad networks and ad
                    servers, content providers, and application providers. These
                    third parties may also use cookies alone or in conjunction
                    with web beacons or other tracking technologies to collect
                    information about you when you use our Services. The
                    information these third parties collect may be associated or
                    matched with certain of your Personal Information which they
                    have already collected, or they may collect information in
                    the future, including Personal Information about your online
                    activities over time and across different websites and other
                    online services. They may also use this information to
                    provide you with interest-based (<em>e.g.</em>, behavioral
                    or retargeted) advertising or other targeted content.
                  </li>
                  <li>
                    We do not control these third parties’ tracking technologies
                    or how they may be used, during your session or over time.
                    If you have any questions about an advertisement or other
                    targeted content, you should contact the responsible
                    provider directly.
                    <ul className="ul2">
                      <li>
                        Some of our Services incorporate Google Analytics
                        Advertising Features, including remarketing with Google
                        Analytics, Google Display Network Impression Reporting,
                        DoubleClick Platform integrations, and Google Analytics
                        Demographic and Interest Reporting. For information
                        about Google Analytics and currently available opt-outs,
                        please click
                        <a href="https://tools.google.com/dlpage/gaoptout/">
                          here
                        </a>
                        .
                      </li>
                      <li>
                        Some of our Services may include advertisements or
                        tracking technologies from Microsoft Bing. To learn more
                        about information collected by Microsoft, please click
                        <a href="http://www.microsoft.com/en-us/privacystatement/Default.aspx">
                          here.
                        </a>
                        You can opt-out of Microsoft’s personalized advertising
                        services by following the instructions found
                        <a href="http://choice.microsoft.com/">here.</a>
                      </li>
                      <li>
                        Some of our Services may include advertisements or
                        tracking technologies from Yahoo!. To learn more about
                        information collected by Yahoo!, please click
                        <a href="https://legal.yahoo.com/us/en/yahoo/privacy/index.html">
                          here
                        </a>
                        . You can opt-out of Yahoo!’s personalized advertising
                        services by following the instructions found
                        <a href="https://legal.yahoo.com/us/en/yahoo/privacy/adinfo/index.html">
                          here
                        </a>
                        <a href="http://choice.microsoft.com/">.</a>
                      </li>
                      <li>
                        You can also generally opt-out of receiving personalized
                        ads from third-party advertisers and ad networks who are
                        members of the
                        <a href="https://thenai.org/opt-out/">
                          Network Advertising Initiative
                        </a>
                        (NAI) or who follow the
                        <a href="https://optout.aboutads.info/?c=2&amp;lang=EN">
                          Digital Advertising Alliance’s Self-Regulatory
                          Principles for Online Behavioral Advertising
                        </a>
                        (DAA) by visiting the opt-out pages on the NAI website
                        and the DAA website, respectively.
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
            </ul>
            <h2
              className="text-2xl font-bold my-4"
              id="personal-information-how-why"
            >
              3. How and why we use your Personal Information
            </h2>
            <p>
              We use your Personal Information only for the purposes described
              in this Privacy Policy (or such other purposes which are
              compatible with the purposes described in this Privacy Policy). If
              you do not provide your Personal Information to us, or if we are
              otherwise prevented from processing your Personal Information, it
              may delay or prevent us from providing certain elements of our
              Services to you.
            </p>
            <p>
              In certain locations (including the EEA and the UK) we are
              required to have a lawful basis for the collection and processing
              of such Personal Information. The table below sets out the
              purposes for which we use your Personal Information and the lawful
              bases upon which we rely. Where we rely upon our (or a third
              party’s) legitimate interests, we have assessed that such
              interests are not overridden by your own rights and interests in
              your Personal Information.
            </p>
            <table className="terms-table">
              <tbody>
                <tr>
                  <td>
                    <p>
                      <em>What we use your Personal Information for</em>
                    </p>
                  </td>
                  <td>
                    <p>
                      <em>Our legal basis (relevant for EEA and UK users)</em>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Providing our Services to you, including the provision of
                      content, advertisements and personalised recommendations
                      and detecting errors or outages.
                    </p>
                    <p>
                      <strong>
                        Categories of Personal Information Processed
                      </strong>
                      :
                    </p>
                    <ul>
                      <li>Information you provide to us directly;</li>
                      <li>
                        Information we collect through automated data collection
                        or tracking technologies.
                      </li>
                    </ul>
                  </td>
                  <td>
                    <p>
                      Consent (where required by applicable law), or for our
                      legitimate interests, <em>e.g.</em>, to provide relevant
                      content to users to ensure user satisfaction and positive
                      return rates.
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Maintaining records of our interactions with you (if any)
                      and providing updates or notices about your queries,
                      accounts, or requests.
                    </p>
                    <p>
                      <strong>
                        Categories of Personal Information Processed
                      </strong>
                      :
                    </p>
                    <ul>
                      <li>Information you provide to us directly.</li>
                      <li>
                        Information we collect through automated data collection
                        or tracking technologies.
                      </li>
                    </ul>
                  </td>
                  <td>
                    <p>Depending on the circumstances:</p>
                    <p>—to comply with our legal and regulatory obligations;</p>
                    <p>
                      —for our legitimate interests, <em>e.g.</em>, making sure
                      that we can keep in touch with you and respond to your
                      inquiries, manage any complaints or otherwise maintain and
                      build upon the relationship we have with you.
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      To notify you in the event of any changes to our Services
                      that may impact you and to provide customer support and
                      respond to inquiries, where applicable.
                    </p>
                    <p>
                      <strong>
                        Categories of Personal Information Processed
                      </strong>
                      :
                    </p>
                    <ul>
                      <li>Information you provide to us directly.</li>
                    </ul>
                  </td>
                  <td>
                    <p>
                      For our legitimate interests, <em>e.g.</em>, making sure
                      that you remain informed about important changes to our
                      Services as part of our ongoing relationship with you and
                      to support your access to the Services and the quality of
                      the Services provided to you.
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>Serving advertisements to you on our Services.</p>
                    <p>
                      <strong>
                        Categories of Personal Information Processed
                      </strong>
                      :
                    </p>
                    <ul>
                      <li>
                        Information we collect through automated data collection
                        or tracking technologies.
                      </li>
                    </ul>
                  </td>
                  <td>
                    <p>
                      Consent (where required by applicable law), or for our
                      legitimate interests, <em>e.g</em>., to support our
                      provision of free online content.
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      To enforce legal rights, comply with applicable laws, for
                      fraud and crime prevention, to defend or undertake legal
                      proceedings, or comply with court orders or similar
                      obligations.
                    </p>
                    <p>
                      <strong>
                        Categories of Personal Information Processed
                      </strong>
                      :
                    </p>
                    <ul>
                      <li>Information you provide to us directly;</li>
                      <li>
                        Information we collect through automated data collection
                        or tracking technologies.
                      </li>
                    </ul>
                  </td>
                  <td>
                    <p>Depending on the circumstances:</p>
                    <p>—to comply with our legal and regulatory obligations;</p>
                    <p>
                      —in other cases, for our legitimate interests,
                      <em>e.g.</em>, to protect our business, interests and
                      rights
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      For the purpose of forming our business strategy,
                      including analysing statistics related to your use of our
                      Services to inform our business planning and the design
                      and selection of future products and features and to
                      otherwise develop, improve, repair, and/or maintain our
                      products and Services.
                    </p>
                    <p>
                      <strong>
                        Categories of Personal Information Processed
                      </strong>
                      :
                    </p>
                    <ul>
                      <li>Information you provide to us directly;</li>
                      <li>
                        Information we collect through automated data collection
                        or tracking technologies.
                      </li>
                    </ul>
                  </td>
                  <td>
                    <p>
                      For our legitimate interests, <em>e.g.</em>, to improve
                      our Services and effectiveness of our business strategy
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>For the purpose of marketing our Services.</p>
                    <p>
                      <strong>
                        Categories of Personal Information Processed
                      </strong>
                      :
                    </p>
                    <ul>
                      <li>Information you provide to us directly;</li>
                      <li>
                        Information we collect through automated data collection
                        or tracking technologies.
                      </li>
                    </ul>
                  </td>
                  <td>
                    <p>
                      As legally required, we obtain consent from recipients
                      prior to sending marketing communications. Recipients can,
                      at any time, opt-out of receiving marketing communications
                      from us.
                    </p>
                    <p>
                      Where consent is not legally required, we may rely on our
                      legitimate interests, <em>e.g.</em>, to promote our
                      business.
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      To allow you to participate in interactive features on our
                      Services (<em>e.g.</em>, quizzes, polls, or promotions).
                    </p>
                    <p>
                      <strong>
                        Categories of Personal Information Processed
                      </strong>
                      :
                    </p>
                    <ul>
                      <li>Information you provide to us directly;</li>
                      <li>
                        Information we collect through automated data collection
                        or tracking technologies.
                      </li>
                    </ul>
                  </td>
                  <td>
                    <p>
                      For our legitimate interests, <em>e.g.</em>, to provide
                      useful and valuable features to our users to ensure user
                      satisfaction and positive return rates.
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>To process payments, where applicable.</p>
                    <p>
                      <strong>
                        Categories of Personal Information Processed
                      </strong>
                      :
                    </p>
                    <ul>
                      <li>Information you provide to us directly.</li>
                    </ul>
                  </td>
                  <td>
                    <p>
                      For our legitimate interests, <em>e.g.,</em> to ensure we
                      receive remuneration for any Services for which we charge
                      fees.
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>For any other purpose communicated to you.</p>
                    <p>
                      <strong>
                        Categories of Personal Information Processed
                      </strong>
                      :
                    </p>
                    <ul>
                      <li>Information you provide to us directly;</li>
                      <li>
                        Information we collect through automated data collection
                        or tracking technologies;
                      </li>
                      <li>
                        Other Personal Information categories, communicated to
                        you.
                      </li>
                    </ul>
                  </td>
                  <td>
                    <p>With your consent.</p>
                  </td>
                </tr>
              </tbody>
            </table>
            <h2 className="text-2xl font-bold my-4" id="dnt">
              4. Do Not Track
            </h2>
            <p>
              Your browser settings may allow you to automatically transmit a
              “Do Not Track” signal to online services you visit, including our
              Services. Note, however, there is no industry consensus as to what
              site and app operators should do with regard to these signals.
              Accordingly, unless and until the law is interpreted to require us
              to do so, we do not monitor or take action with respect to “Do Not
              Track” signals. For more information on “Do Not Track,” visit
              <a href="http://www.allaboutdnt.com/">
                http://www.allaboutdnt.com
              </a>
              .
            </p>
            <h2
              className="text-2xl font-bold my-4"
              id="personal-information-who"
            >
              5. Who we share your Personal Information with
            </h2>
            <p>
              As necessary for the purposes described above, we may share your
              Personal Information with:
            </p>
            <ul>
              <li>members of the System1 Group;</li>
              <li>
                third party IT vendors (<em>e.g.</em> hosting, cloud services,
                networking, cybersecurity platforms, telecom providers, managed
                services providers, customer service platforms, technical
                support service providers and payment processors);
              </li>
              <li>
                third party media, advertising and marketing vendors and
                monetization partners;
              </li>
              <li>
                data analytics providers, social media networks, native
                advertising networks, programmatic advertising platforms, search
                engines, monetization partners, syndication and distribution
                partners, customer engagement services, push notification
                providers (to provide services that include, but are not limited
                to, product recommendations, delivering marketing messages
                including advertisements, and sending you relevant offers);
              </li>
              <li>
                partners with whom we work to improve our Services and/or
                business processes;
              </li>
              <li>
                third parties in connection with whom we have received your
                <strong>
                  <em>express&nbsp;consent </em>
                </strong>
                to share your Personal Information;
              </li>
              <li>
                our external auditors, professional advisors, law enforcement
                agencies, courts, tribunals and regulatory bodies to comply with
                our legal and regulatory obligations; and
              </li>
              <li>
                other parties that have or may acquire control or ownership of
                some or all of our business (and our or their professional
                advisers) in connection with a significant corporate transaction
                or restructuring, including a merger, acquisition, asset sale,
                initial public offering or in the event of our
                insolvency—usually, information will be anonymised but this may
                not always be possible. The recipient of any of your information
                will be bound by confidentiality obligations.
              </li>
            </ul>
            <p>
              Additionally, we may disclose aggregated and deidentified
              information that does not identify any individual.
            </p>
            <h2 className="text-2xl font-bold my-4" id="third-party-links">
              6. Links to third party sites and content
            </h2>
            <p>
              Our Services may include links to other sites or content operated
              by third parties. We do not exercise control over third party
              sites, services or content, and we are not responsible for the
              privacy practices of any such third parties. Once you leave our
              Services via a link or by clicking on an advertisement, it is
              important that you check the applicable privacy policy of the
              third party’s site or service. The fact that we link to a website
              or display a banner ad or other type of advertisement is not an
              endorsement, authorization, or representation of our affiliation
              with that third party, nor is it an endorsement of their privacy
              or information security policies or practices.
            </p>
            <h2
              className="text-2xl font-bold my-4"
              id="personal-information-duration"
            >
              7. How long your Personal Information will be kept
            </h2>
            <p>
              We retain your Personal Information for a period that is
              reasonably necessary in order that we can achieve the purposes for
              which the Personal Information was collected, including to provide
              you with the Services requested and to support our business
              purposes as described above in the section and table column titled
              “
              <strong>
                <em>What We Use Your Personal Information For</em>
              </strong>
              ” and as described below in the section titled “
              <strong>
                <em>
                  Business or Commercial Purposes for the Collection and
                  Processing of Personal Information
                </em>
              </strong>
              “. As a result, different retention periods apply to different
              types of information.
            </p>
            <p>
              Following the end of the relevant retention period, we will delete
              or anonymise your Personal Information. Personal Information may
              be kept for longer periods to the extent necessary to comply with
              a legal or regulatory requirement to which we are subject, or in
              connection with any legal disputes or claims. Even if you request
              a deletion, we may be required to maintain your information for as
              long as necessary to comply with our legal or regulatory
              compliance needs (<em>e.g.</em>, maintaining records of
              transactions you have made with us); to exercise, establish or
              defend legal claims; and/or to protect against fraudulent or
              abusive activity on our Services.
            </p>
            <h2
              className="text-2xl font-bold my-4"
              id="international-transfers"
            >
              8. International transfers
            </h2>
            <p>
              Our Services are hosted primarily in the U.S. If you are accessing
              our Services from outside of the U.S., please be aware that
              Personal Information collected through our Services may be
              transferred to, stored in or otherwise processed in the U.S. (or
              such other jurisdictions where we have engaged a third party to
              provide services). The laws of such jurisdictions may be different
              from those of your country of residence, and in some cases, may
              not be as protective.
            </p>
            <p>
              In the event that we transfer your Personal Information, we take
              steps to ensure your Personal Information is safeguarded in
              compliance with applicable data protection laws. In connection
              with Personal Information originating in the UK or the EEA, such
              safeguards may include our execution of the Standard Contractual
              Clauses and, where applicable, the UK International Data Transfer
              Addendum, with the recipient of such Personal Information. System1
              and the System1 Group are required to protect Personal Information
              that they process from the UK or the EEA in accordance with
              applicable European data protection laws. If you require more
              information about our international data transfers and the
              applicable safeguards, please contact us using the details
              provided in the “
              <strong>
                <em>How to contact us</em>
              </strong>
              ” section of this Privacy Policy.
            </p>
            <h2 className="text-2xl font-bold my-4" id="supplemental-rights">
              9. Supplemental U.S. state specific privacy rights and disclosures
            </h2>
            <p>
              This section applies only to Personal Information collected about
              consumers residing in the United States. Depending on the state in
              which you reside and the way in which you interact with us and our
              Services, you may be entitled to additional disclosures regarding
              the Personal Information we collect about you. You also may have
              certain rights regarding that Personal Information.
            </p>
            <p>
              <strong>
                <em>Personal Information we have collected </em>
              </strong>
            </p>
            <p>
              In the preceding 12 months, we may have collected or processed the
              following categories of Personal Information:
            </p>
            <ul>
              <li>
                Identifiers — such as name, email address, device type, web
                browser, operating system or other similar identifiers;
              </li>
              <li>
                Unique online identifiers — such as IP address, device IDs
                derived from device type, or other similar identifiers;
              </li>
              <li>
                Certain commercial information — such as the Services,
                advertisement offerings or links to third party websites
                accessed, acquired or considered and related metrics;
              </li>
              <li>
                Other electronic network activity information — such as browsing
                history, search history and information regarding interactions
                with our websites or our Services;
              </li>
              <li>
                Location information – geo-location information about where you
                are accessing our Services (<em>e.g.</em>, if you access our
                Services on your mobile or tablet device we may collect
                information about your device’s precise location to the extent
                feasible);
              </li>
              <li>
                Online viewing activities and habits (<em>e.g.</em>, videos
                viewed, pages viewed);
              </li>
              <li>Consumer’s precise geolocation (where applicable);</li>
              <li>
                Certain demographic data (<em>e.g.</em>, gender, age, etc.,
                where applicable); and
              </li>
            </ul>
            <p>
              <strong>
                <em>
                  Business or commercial purposes for the collection and
                  processing of Personal Information
                </em>
              </strong>
            </p>
            <p>
              We may have collected these categories of Personal Information for
              the following purposes (and including for any purposes otherwise
              described in this Privacy Policy):
            </p>
            <ul>
              <li>
                Operate, manage, and maintain our businesses and infrastructure;
              </li>
              <li>
                Provide, develop, improve, repair, and maintain our products and
                Services;
              </li>
              <li>
                Performing services on behalf of our various businesses, such as
                user retention, providing content recommendations and/or
                processing information;
              </li>
              <li>Fraud and crime prevention;</li>
              <li>Debugging errors in systems;</li>
              <li>Marketing and advertising;</li>
              <li>
                Perform accounting, audit, and other internal functions, such as
                internal investigations;
              </li>
              <li>Comply with law, legal process, and internal policies;</li>
              <li>
                Internal research, analytics and development — <em>e.g.</em>,
                user-preference analytics;
              </li>
              <li>
                Developing, maintaining, provisioning or upgrading networks,
                products, services, or devices;
              </li>
              <li>
                Payment processing and verification (where applicable); and
              </li>
              <li>Customer support and inquiries.</li>
            </ul>
            <p>
              We do not collect or process sensitive Personal Information for
              the purpose of inferring characteristics about individuals.
            </p>
            <p>
              <strong>
                <em>Sources of Personal Information</em>
              </strong>
            </p>
            <p>We collect Personal Information:</p>
            <ul>
              <li>
                Directly from you, including technical and usage information
                when you use our Services;
              </li>
              <li>
                From linked sites, such as social media sites, and other
                third-party platforms;
              </li>
              <li>From our various affiliated entities;</li>
              <li>
                From our joint-ventures and promotional and strategic partners;
              </li>
              <li>From information suppliers;</li>
              <li>
                From distributors, service providers and other vendors; and
              </li>
              <li>From marketing mailing lists</li>
            </ul>
            <p>
              <strong>
                <em>Retention of Personal Information</em>
              </strong>
            </p>
            <p>
              Our retention practices are described
              <a href="https://system1.com/terms/privacy-policy#personal-information-duration">
                above
              </a>
              under the section titled “
              <strong>
                <em>How long your Personal Information will be kept</em>
              </strong>
              .”
            </p>
            <p>
              <strong>
                <em>Categories of Personal Information we disclose </em>
              </strong>
            </p>
            <p>
              As described
              <a href="https://system1.com/terms/privacy-policy#personal-information-who">
                above
              </a>
              under the section titled “
              <strong>
                <em>Who we share your Personal Information with</em>
              </strong>
              “, we share your Personal Information with companies such as
              third-party media and marketing vendors, data analytics providers,
              social media networks, native advertising networks, programmatic
              advertising platforms, customer engagement services, push
              notification providers, and others to provide services that
              include, but are not limited to, product recommendations,
              delivering marketing messages including advertisements, and
              sending you relevant offers.
            </p>
            <p>
              Such disclosure of Personal Information may be considered a “sale”
              or “sharing” of information under applicable state law. For
              example, under California law, a “sale” is the disclosure of
              Personal Information to a third party for monetary consideration
              or anything of value, and a “share” is the disclosure of Personal
              Information to a third party for cross-context behavioral
              advertising, whether or not for monetary or other valuable
              consideration.
            </p>
            <p>
              The categories of Personal Information that we disclose in this
              manner are as follows:
            </p>
            <ul>
              <li>
                Identifiers — such as email address, device type, web browser,
                operating system or other similar identifiers;
              </li>
              <li>
                Unique and online identifiers — such as IP address, device IDs
                derived from device type, or other similar identifiers;
              </li>
              <li>
                Certain commercial information — such as Services accessed,
                acquired or considered;
              </li>
              <li>
                Internet or other electronic network activity information — such
                as browsing history, search history and information regarding
                interactions with our websites or Services;
              </li>
              <li>
                Location information – geo-location information about where you
                are accessing our Services (<em>e.g.</em>, if you access our
                Services on your mobile or tablet device we may collect
                information about your device’s precise location to the extent
                feasible);
              </li>
              <li>
                Online viewing activities and habits (e.g., videos viewed, pages
                viewed); and
              </li>
              <li>Consumer’s precise geolocation (where applicable).</li>
            </ul>
            <p>
              Some of our processing activities may constitute “targeted
              advertising” as that term is defined in applicable law.
            </p>
            <p>
              <strong>
                <em>Your U.S. state privacy rights </em>
              </strong>
            </p>
            <p>
              Depending on the state in which you reside, you may have certain
              privacy rights regarding your Personal Information, including (if
              applicable):
            </p>
            <ul>
              <li>
                The right to know what Personal Information we have collected
                about you, including the categories of Personal Information, the
                categories of sources from which we collected Personal
                Information, the business or commercial purposes for collecting,
                selling or sharing Personal Information, the categories of third
                parties to whom we disclose Personal Information, and the
                specific pieces of Personal Information we collected about you;
              </li>
              <li>
                The right to confirm whether or not we are processing your
                Personal Information;
              </li>
              <li>
                The right to obtain a copy of your Personal Information that you
                previously provided to us in a portable and, to the extent
                technically feasible, readily usable format that allows you to
                transmit the information to another controller without
                hindrance, where the processing is carried out by automated
                means;
              </li>
              <li>
                The right to delete Personal Information that we collected from
                and/or about you, subject to certain exceptions;
              </li>
              <li>
                The right to correct inaccurate Personal Information that we
                maintain about you, subject to certain exceptions;
              </li>
              <li>
                The right, if applicable, to opt out of (1) the sale or sharing
                of your Personal Information; (2) targeted advertising; and (3)
                profiling in furtherance of decisions that produce legal or
                similarly significant effects concerning you;
              </li>
              <li>
                If we use or disclose sensitive Personal Information for
                purposes other than those allowed by applicable law, the right
                to limit our use or disclosure;
              </li>
              <li>
                If we are required by applicable law to obtain your consent to
                process sensitive Personal Information, the right to withdraw
                your consent; and
              </li>
              <li>
                The right not to receive discriminatory treatment by us for the
                exercise of your privacy rights.
              </li>
            </ul>
            <p>
              You can
              <a href="https://system1.com/terms/privacy-policy/privacy-inquiries">
                contact us
              </a>
              with your request through one of the contact methods described
              under the section titled “
              <strong>
                <em>How to contact us</em>
              </strong>
              “. We may ask you to provide information that will enable us to
              verify your identity in order to comply with your request to
              exercise your privacy rights. Consumers in some states may also
              authorize an agent to make requests on their behalf. In
              particular, when a California consumer authorizes an agent to make
              a request on their behalf, we may require the agent to provide
              proof of signed permission from the consumer to submit the
              request, or we may require the consumer to verify their own
              identity to us or confirm with us that they provided the agent
              with permission to submit the request on their behalf. In some
              instances, we may decline to honor your request if an exception
              applies under applicable law. We will respond to your request in
              accordance with the requirements of applicable law.
            </p>
            <p>
              You may have the right to appeal our decisions made with respect
              to on your request. To appeal our decision on your request, you
              may contact us through one of the contact methods described under
              the section titled “
              <strong>
                <em>How to contact us</em>
              </strong>
              “. Please enclose a copy of, or otherwise specifically reference,
              our decision on your request, so that we may adequately address
              your appeal. We will respond to your appeal in accordance with
              applicable law.
            </p>
            <p>
              You may also request information about our practices related to
              the disclosure of your Personal Information by members of System1
              Group to certain third parties for their direct marketing
              purposes. You may be able to opt out of our sharing of your
              Personal Information with unaffiliated third parties for the third
              parties’ direct marketing purposes in certain circumstances. You
              can
              <a href="https://system1.com/terms/privacy-policy/privacy-inquiries">
                contact us{" "}
              </a>
              with your request through one of the contact methods described
              under the section titled “
              <strong>
                <em>How to contact us</em>
              </strong>
              “.
            </p>
            <p>
              <strong>
                <em>
                  Your right to opt out of the sale or sharing of Personal
                  Information
                </em>
              </strong>
            </p>
            <p>
              You may have the right to opt out of the sale of your Personal
              Information by contacting us through one of the contact methods
              described under the section titled “
              <strong>
                <em>How to contact us</em>
              </strong>
              ” or by clicking on the link “Do Not Sell or Share My Personal
              Information” at the bottom of the page.
            </p>
            <p>
              However, please note that your use of our website may still be
              tracked by us and our vendors or partners to perform functions
              that are necessary for our businesses, such as hosting our
              website, ensuring there is no fraud (click-fraud, fraudulent or
              bot traffic), etc. These entities are contractually obligated to
              keep this information confidential, and will not use it for any
              purpose other than for the services they provide to our
              businesses.
            </p>
            <h2 className="text-2xl font-bold my-4" id="supplemental-info">
              10. Supplemental Information for persons in the EEA and UK
            </h2>
            <p>
              If you are located in the European Economic Area (EEA) or the
              United Kingdom (UK), you may have the following rights with
              respect to the Personal Information we process about you. Please
              note that these rights are not necessarily absolute (and certain
              qualifications may apply). If you have questions related to these
              rights and their applicability to you, you may contact us.
            </p>
            <table className="terms-table">
              <tbody>
                <tr>
                  <td>
                    <p>
                      <strong>Right</strong>
                    </p>
                  </td>
                  <td>
                    <p>
                      <strong>Information</strong>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>Access</p>
                  </td>
                  <td>
                    <p>
                      The right to be provided with information about the
                      Personal Information we process about you and to receive a
                      copy of your Personal Information.
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>Rectification</p>
                  </td>
                  <td>
                    <p>
                      The right to require us to correct any inaccuracies in
                      your Personal Information, as applicable.
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>Erasure (also known as the right to be forgotten)</p>
                  </td>
                  <td>
                    <p>
                      The right to require that we delete your Personal
                      Information, in certain situations, where certain grounds
                      for deletion apply (including, for example, where we
                      relied on your consent to process the Personal Information
                      and you have withdrawn that consent and we have no other
                      legal grounds on which to process the Personal
                      Information).
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>Restriction of processing</p>
                  </td>
                  <td>
                    <p>
                      The right to require us to restrict or limit the
                      processing of your Personal Information in certain
                      circumstances, for example where you contest the accuracy
                      of the data
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>Data portability</p>
                  </td>
                  <td>
                    <p>
                      The right to receive the Personal Information you provided
                      to us, in a structured, commonly used and machine-readable
                      format and/or have that Personal Information transmitted
                      to a third party (in certain circumstances, where
                      technically feasible)
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>To object</p>
                  </td>
                  <td>
                    <p>The right to object:</p>
                    <p>
                      —at any time to your Personal Information being processed
                      for direct marketing (including profiling);
                    </p>
                    <p>
                      —in certain other situations to our continued processing
                      of your Personal Information, <em>e.g.</em>, processing
                      carried out for the purpose of our legitimate interests,
                      unless there are compelling legitimate grounds for the
                      processing to continue or the processing is required for
                      the establishment, exercise or defence of legal claims.
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Not to be subject to automated individual decision making
                    </p>
                  </td>
                  <td>
                    <p>
                      The right not to be subject to automated decision making,
                      that gives rise to a legal effect that has a significant
                      impact upon you.
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>The right to withdraw consents</p>
                  </td>
                  <td>
                    <p>
                      Where we have relied on your consent as the basis for
                      processing your Personal Information, you may have a right
                      to withdraw that consent at any time. You may withdraw
                      consents by visiting
                      <a href="https://system1.com/terms/privacy-policy/privacy-inquiries">
                        https://system1.com/terms/privacy-policy/privacy-inquiries
                      </a>
                    </p>
                    <p>
                      Withdrawing a consent will not affect the lawfulness of
                      our use of your Personal Information in reliance on that
                      consent at any time before it was withdrawn.
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>The right to complain</p>
                  </td>
                  <td>
                    <p>
                      You may submit a complaint about our processing of your
                      Personal Information to us at any time; you also have the
                      right to take your complaint to a data protection
                      supervisory authority.
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
            <p>
              If you would like to exercise any of the rights set forth above,
              please:
            </p>
            <ul>
              <li>
                Email us—see below: “
                <strong>
                  <em>How to contact us</em>
                </strong>
                “; and
              </li>
              <li>
                provide enough information to allow us to verify your identity
                (this may include providing your name or, where relevant /
                applicable, a customer account number (for example), and any
                additional identity information we may reasonably request from
                you);
              </li>
              <li>
                let us know what right you want to exercise and the Personal
                Information to which your request relates.
              </li>
            </ul>
            <p>For the purposes of EEA and UK data protection law:</p>
            <ul>
              <li>
                System1 OpCo, LLC is the data controller and can be contacted
                using any of the means set out in the “
                <strong>
                  <em>How to contact us</em>
                </strong>
                ” section below.
              </li>
              <li>
                <strong>EEA:</strong> VeraSafe has been appointed as System1
                OpCo, LLC’s representative in the European Union for data
                protection matters, pursuant to Article 27 of the General Data
                Protection Regulation of the European Union. If you are in the
                European Economic Area, VeraSafe can be contacted in connection
                with data protection matters, in addition to or instead of using
                the information provided in the “
                <strong>
                  <em>How to contact us</em>
                </strong>
                ” section below. To make such an inquiry, please contact
                VeraSafe using this contact form:
                <a href="https://verasafe.com/public-resources/contact-data-protection-representative">
                  https://verasafe.com/public-resources/contact-data-protection-representative
                </a>
                or via telephone at: +420 228 881 031.
              </li>
            </ul>
            <p>Alternatively, VeraSafe can be contacted at:</p>
            <p>
              <strong>VeraSafe Netherlands BV</strong>
            </p>
            <p>Keizersgracht 555 1017 DR Amsterdam</p>
            <p>Netherlands</p>
            <div id="rampjs_ip93"></div>
            <ul>
              <li>
                <strong>UK:</strong> VeraSafe has been appointed as System1
                OpCo, LLC’s representative in the United Kingdom for data
                protection matters, pursuant to Article 27 of the United Kingdom
                General Data Protection Regulation. If you are located within
                the United Kingdom, VeraSafe can be contacted in connection with
                data protection matters, in addition to or instead of using the
                information provided in the “
                <strong>
                  <em>How to contact us</em>
                </strong>
                ” section below. To make such an inquiry, please contact
                VeraSafe using this contact form:
                https://verasafe.com/public-resources/contact-data-protection-representative
                or via telephone at: +44 (20) 4532 2003.
              </li>
            </ul>
            <p>Alternatively, VeraSafe can be contacted at:</p>
            <p>
              <strong>VeraSafe United Kingdom Ltd.</strong>
            </p>
            <p>37 Albert Embankment</p>
            <p>London SE1 7TL</p>
            <p>United Kingdom</p>
            <h2 className="text-2xl font-bold my-4" id="personal-info-secure">
              11. Keeping your Personal Information secure
            </h2>
            <p>
              We have appropriate security measures to prevent Personal
              Information from being accidentally accessed unlawfully. We limit
              access to your Personal Information to those who have a genuine
              business need to access and are subject to a duty of
              confidentiality. Depending on the nature of the risks presented by
              the proposed processing of your personal data, we will have in
              place the following appropriate security measures:
            </p>
            <ul>
              <li>
                Organizational measures (including, but not limited to, staff
                training and policy development);
              </li>
              <li>
                Technical measures (including, but not limited to, physical
                protection of data, pseudonymization and encryption); and
              </li>
              <li>
                Securing ongoing availability, integrity, and accessibility
                (including, but not limited to, ensuring appropriate back-ups of
                personal data are held).
              </li>
            </ul>
            <p>
              We also have procedures to deal with any suspected or confirmed
              data security breach. We will notify you and any applicable
              regulator of a data security breach when and where we are legally
              required to do so.
            </p>
            <h2 className="text-2xl font-bold my-4" id="children-under-16">
              12. Children Under the Age of 16
            </h2>
            <p>
              Use of our Services is not intended for and not directed at
              children under 16 years of age. We do not knowingly collect, sell,
              or share Personal Information from children under 16 and do not
              knowingly target our Services to children under the age of 16.
              Persons under 16 years of age may not use or provide any Personal
              Information through our Services. If we learn we have collected or
              received Personal Information from a child under the age of 16, we
              will delete that Personal Information in accordance with
              applicable law. If you believe we might have any information from
              or about a child under the age of 16, please contact us.
            </p>
            <h2 className="text-2xl font-bold my-4" id="policy-changes">
              13. Changes to this Privacy Policy
            </h2>
            <p>This Privacy Policy was last updated on July 5, 2023.</p>
            <p>
              It is our policy to post any changes we make to our Privacy Policy
              on this page. You are responsible for periodically visiting this
              Privacy Policy to check for any changes. If we make material
              changes to how we treat our users’ Personal Information, we will
              let you know through a notice available in connection with your
              use of our Services after implementing such changes (you are
              responsible for ensuring we have an up-to-date active and
              deliverable email address for you, where relevant).
            </p>
            <h2 className="text-2xl font-bold my-4" id="contact-us">
              14. How to contact us
            </h2>
            <p>
              Please contact us if you have any questions about this Privacy
              Policy or the information we may hold about you, to exercise a
              right under applicable data protection laws, to make a complaint
              or make inquiries.
            </p>
            <p className="my-3">Our contact details are shown below:</p>

            <p>
              System1 OpCo, LLC
              <br />
              4235 Redwood Avenue
              <br />
              Los Angeles, CA 90066
            </p>
          </section>
        </div>
      </article>
    </div>
  );
};

export default PrivacyPolicy;
