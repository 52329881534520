import axios from "axios";
import React, { useState } from "react";
import { useAuthContext } from "../context/authContext";
import { BASE_URL } from "../constants";

const AddHost = () => {
  const { token } = useAuthContext();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [path, setPath] = useState("")
  const [loading, setLoading] = useState(false);

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);

    await axios
      .post(
        `${BASE_URL}/api/bound-add`,
        { host: username, url: password, path },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      )
      .then((res) => {
        alert("Done");
      })
      .catch((err) => {
        alert(err?.response?.data?.message);
      });

    setLoading(false);
  };

  return (
    <div>
      <div class="bg-white p-8 rounded-lg shadow-md w-96 mt-6">
        <h1 class="text-2xl font-semibold mb-6">Add OutBounding Url</h1>
        <form onSubmit={handleLogin}>
          <div class="mb-4">
            <label
              for="username"
              class="block text-gray-700 text-sm font-bold mb-2"
            >
              Hostname
            </label>
            <input
              type="text"
              id="username"
              value={username}
              onChange={(e) => setUsername(e?.target.value)}
              maxLength={200}
              class="border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
              placeholder="https://search.com"
              required
            />
          </div>
          <div class="mb-6">
            <label
              for="password"
              class="block text-gray-700 text-sm font-bold mb-2"
            >
              Outbound ID
            </label>
            <input
              type="text"
              id="password"
              value={password}
              onChange={(e) => setPassword(e?.target.value)}
              maxLength={200}
              class="border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
              placeholder="1134432532"
              required
            />
          </div>
          <div class="mb-6">
            <label
              for="path"
              class="block text-gray-700 text-sm font-bold mb-2"
            >
              /Path
            </label>
            <input
              type="text"
              id="path"
              value={path}
              onChange={(e) => setPath(e?.target.value)}
              maxLength={200}
              class="border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
              placeholder="iksofsp-sdkfnskld"
              required
            />
          </div>
          <button
            disabled={loading}
            type="submit"
            class="disabled:bg-gray-400 w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline-blue active:bg-blue-800"
          >
            Add
          </button>
        </form>
      </div>
    </div>
  );
};

export default AddHost;
