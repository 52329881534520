import axios from "axios";
import React, { useState } from "react";
import { BASE_URL } from "../constants";
import { useAuthContext } from "../context/authContext";

const Cards = ({ data, setData, setDeleted }) => {
  const [loading, setLoading] = useState(false);
  const { token } = useAuthContext();

  const editThisAdDetails = () => {
    setData(data);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const deleteSponsor = async () => {
    setLoading(true);
    await axios
      .delete(`${BASE_URL}/api/delete-ad/${data?._id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      })
      .then((res) => {
        alert("Deleted successfully.");
        setDeleted(true);
      })
      .catch((error) => {
        alert("Something went wrong please try again!");
      });
    setLoading(false);
  };

  return (
    <>
      <div className="border p-4 rounded shadow hover:shadow-md transition duration-300">
        <div className="flex items-start mb-2">
          <p className="block text-gray-700 font-semibold mr-4 w-40 min-w-[160px]">
            Link :
          </p>
          <p className="">{data?.link}</p>
        </div>
        <div className="flex items-start mb-2">
          <p className="block text-gray-700 font-semibold mr-4 w-40 min-w-[160px]">
            Display Link :
          </p>
          <p className="">{data?.displayLink}</p>
        </div>
        <div className="flex items-start mb-2">
          <p className="block text-gray-700 font-semibold mr-4 w-40 min-w-[160px]">
            Heading :
          </p>
          <p className="font-semibold">{data?.mainHeading}</p>
        </div>
        <div className="flex items-start mb-2">
          <p className="block text-gray-700 font-semibold mr-4 w-40 min-w-[160px]">
            Main Discription :
          </p>
          <p className="">{data?.mainDescription}</p>
        </div>
        <div className="flex items-start mb-2">
          <p className="block text-gray-700 font-semibold mr-4 w-40 min-w-[160px]">
            Query :
          </p>
          <p className="">{data?.query}</p>
        </div>
        <div className="flex items-start mb-2">
          <div>
            <p className="block text-gray-700 font-semibold mr-4 w-40 min-w-[160px] mb-3">
              Sub Headings :
            </p>
            <button
              type="button"
              className="px-4 py-1 text-white rounded bg-green-500"
              onClick={editThisAdDetails}
            >
              Edit
            </button>
            <button
              disabled={loading}
              type="button"
              className="px-6 py-1 text-white rounded bg-red-500 ml-2 disabled:bg-gray-400"
              onClick={deleteSponsor}
            >
              Delete
            </button>
          </div>
          <div className="flex flex-wrap gap-1 justify-end">
            {data?.subHeadings?.map((d) => (
              <div className="px-4 py-1 bg-gray-200 rounded">
                <p className="font-semibold">{d?.heading}</p>
                <p className="text-sm">{d?.description}</p>
                <p className="text-sm text-blue-700">{d?.link}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Cards;
