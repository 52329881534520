import React from "react";
import { useAuthContext } from "../context/authContext";
import LoginPage from "./LoginPage";
import AddPage from "./AddPage";

const Dashboard = () => {
  const { token, setToken } = useAuthContext();

  return token ? <AddPage /> : <LoginPage />;
};

export default Dashboard;
